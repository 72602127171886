import { call, put, type CallEffect, type PutEffect } from 'redux-saga/effects'

import { instance } from '../../../api'
import { type IBusinessConfigurationResponse } from '../../../api/business/businessAPI.types'
import {
  type RetrieveBusinessConfig,
  type IRetrieveBusinessConfigAction
} from './businessMiddleware.types'

const retrieveBusinessConfig =
  async (): Promise<IBusinessConfigurationResponse> => {
    const result = await instance({
      url: 'api/v1/businesses/configuration',
      method: 'GET'
    })
    return result.data?.data
  }

function* retrieveBusinessConfigSaga(
  action: IRetrieveBusinessConfigAction
): Generator<
  | CallEffect<IBusinessConfigurationResponse>
  | PutEffect<{ type: RetrieveBusinessConfig; payload: unknown }>,
  void,
  unknown
> {
  try {
    const businessConfigData = yield call(retrieveBusinessConfig)
    yield put({ type: 'RetrieveBusinessConfig', payload: businessConfigData })
  } catch (error) {
    console.log(error, 'error')
  }
}

export default retrieveBusinessConfigSaga
