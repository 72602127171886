import { configureStore, type ThunkAction, type Action } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { apiService } from '../api'
import registerReducer from '../redux/slices/auth/registerSlice'
import dashboardHeaderReducer from './slices/dashboard/dashboardHeaderSlice'
import businessReducer from '../redux/slices/business/businessSlice'
import businessProfileReducer from '../redux/slices/business/businessProfileSlice'
import customerReducer from '../redux/slices/customer/customerSlice'
import userReducer from '../redux/slices/dashboard/userSlice'
import orderReducer from '../redux/slices/order/orderSlice'
import walletReducer from '../redux/slices/wallet/walletSlice'
import metricsReducer from '../redux/slices/metrics/metricsSlice'
import sloudSaga from './saga'

const sagaMiddleWare = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    [apiService.reducerPath]: apiService.reducer,
    registerModal: registerReducer,
    dashboardHeader: dashboardHeaderReducer,
    business: businessReducer,
    businessProfile: businessProfileReducer,
    customer: customerReducer,
    userSlice: userReducer,
    orderSlice: orderReducer,
    walletSlice: walletReducer,
    metricsSlice: metricsReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiService.middleware).concat(sagaMiddleWare)
})

sagaMiddleWare.run(sloudSaga)

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
