import { type PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import type { IVirtualAccount } from '../../../api/business/businessAPI.types'
import type {
  RetrieveVirtualAccount,
  RetrieveSetup,
  RetrieveBusinessConfig
} from '../../saga/business/businessMiddleware.types'
import { type RootState } from '../../store'

interface ISetupData {
  id: string
  maintenance_mode: boolean
  setup: {
    add_products: boolean
    create_wallet: boolean
    verify_phone_number: boolean
    update_personal_details: boolean
    add_store_link_to_socials: boolean
    metadata: {
      is_kyc_submitted: boolean
      is_business_verified: boolean
    }
    update_business_information: {
      add_owner_details: boolean
      add_delivery_areas: boolean
      add_business_documents: boolean
      update_business_profile: boolean
    }
  }
}

interface IBusinessConfigData {
  kind: string
  name: string
  capped: number
  figure: number
  preferences: {
    paymentsMethods: {
      bank_transfer: boolean
    }
    mode_of_delivery: string
    mode_of_transaction_charge: string
  }
}

interface IBusinessSlice {
  virtualAccount: Partial<IVirtualAccount>
  setupData: Partial<ISetupData>
  businessConfigData: Partial<IBusinessConfigData>
}

const RetrieveAccountAction = createAction<
  IVirtualAccount,
  RetrieveVirtualAccount
>('RetrieveVirtualAccount')

const RetrieveSetupAction = createAction<ISetupData, RetrieveSetup>(
  'RetrieveSetup'
)

const RetrieveBusinessConfigAction = createAction<
  IBusinessConfigData,
  RetrieveBusinessConfig
>('RetrieveBusinessConfig')

const initialState: IBusinessSlice = {
  virtualAccount: {},
  setupData: {},
  businessConfigData: {}
}

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setVirtualAccount: (
      state: IBusinessSlice,
      action: PayloadAction<Partial<IVirtualAccount>>
    ) => {
      state.virtualAccount = action.payload
    },
    setSetupData: (
      state: IBusinessSlice,
      action: PayloadAction<Partial<ISetupData>>
    ) => {
      state.setupData = action.payload
    },
    setBusinessConfigData: (
      state: IBusinessSlice,
      action: PayloadAction<Partial<IBusinessConfigData>>
    ) => {
      state.businessConfigData = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(RetrieveAccountAction, (state, action) => {
        state.virtualAccount = action.payload
      })
      .addCase(RetrieveSetupAction, (state, action) => {
        state.setupData = action.payload
      })
      .addCase(RetrieveBusinessConfigAction, (state, action) => {
        state.businessConfigData = action.payload
      })
  }
})

const { actions, reducer } = businessSlice
export const { setVirtualAccount, setSetupData } = actions
export const getVirtualAccount = (state: RootState): Partial<IVirtualAccount> =>
  state.business.virtualAccount
export const getSetupData = (state: RootState): Partial<ISetupData> =>
  state.business.setupData
export const businessConfigData = (
  state: RootState
): Partial<IBusinessConfigData> => state.business.businessConfigData
export default reducer
