import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IDashboardHeaderSlice {
  pageHeader: string
  pageSubHeader: string
  isShowDashboardModal: boolean
  activeDashboardModal: '' | 'sidebarModal' | 'welcomeModal' | 'signOutModal'
}

const initialState: IDashboardHeaderSlice = {
  pageHeader: '',
  pageSubHeader: '',
  isShowDashboardModal: false,
  activeDashboardModal: ''
}

export const dashboardHeaderSlice = createSlice({
  name: 'dashboardHeaderSlice',
  initialState,
  reducers: {
    setPageHeader: (
      state: IDashboardHeaderSlice,
      action: PayloadAction<string>
    ) => {
      const { payload } = action
      state.pageHeader = payload
    },
    setPageSubHeader: (
      state: IDashboardHeaderSlice,
      action: PayloadAction<string>
    ) => {
      const { payload } = action
      state.pageSubHeader = payload
    },
    setIsShowDashboardModal: (
      state: IDashboardHeaderSlice,
      action: PayloadAction<boolean>
    ) => {
      const { payload } = action
      state.isShowDashboardModal = payload
    },
    setActiveDashboardModal: (
      state: IDashboardHeaderSlice,
      action: PayloadAction<
        '' | 'sidebarModal' | 'welcomeModal' | 'signOutModal'
      >
    ) => {
      const { payload } = action
      state.activeDashboardModal = payload
    }
  }
})

const { actions, reducer } = dashboardHeaderSlice
export const {
  setPageHeader,
  setPageSubHeader,
  setActiveDashboardModal,
  setIsShowDashboardModal
} = actions
export default reducer
