import { instance } from '../../../api'
import { type ISetupResponse } from '../../../api/kyc/kycAPI.types'
import {
  type RetrieveSetup,
  type IRetrieveSetupAction
} from './businessMiddleware.types'
import { call, put, type CallEffect, type PutEffect } from 'redux-saga/effects'

const retrieveSetup = async (): Promise<ISetupResponse> => {
  const result = await instance({
    url: 'api/v1/stores/setup',
    method: 'GET'
  })
  return result.data?.data
}

function* retrieveSetupSaga(
  action: IRetrieveSetupAction
): Generator<
  | CallEffect<ISetupResponse>
  | PutEffect<{ type: RetrieveSetup; payload: unknown }>,
  void,
  unknown
> {
  try {
    const setupData = yield call(retrieveSetup)
    yield put({ type: 'RetrieveSetup', payload: setupData })
  } catch (error) {
    console.log(error, 'error')
  }
}

export default retrieveSetupSaga
