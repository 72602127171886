import { type ForkEffect, takeEvery } from 'redux-saga/effects'

import retrieveBusinessSaga from './saga/business/retrieveVirtualAccountSaga'
import getUserSaga from './saga/user/getUserSaga'
import retrieveSetupSaga from './saga/business/retrieveSetupSaga'
import retrieveBusinessConfigSaga from './saga/business/retrieveBusinessConfigSaga'

function* sloudSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery('TriggerRetrieveVirtualAccountAction', retrieveBusinessSaga)
  yield takeEvery('TriggerRetrieveSetupAction', retrieveSetupSaga)
  yield takeEvery('TriggerGetUserAction', getUserSaga)
  yield takeEvery(
    'TriggerRetrieveBusinessConfigAction',
    retrieveBusinessConfigSaga
  )
}

export default sloudSaga
